@import '_mixins_layouts';
@import '_mixins_typography';
@import '_mixins_responsive';


/// Define a button state (default, hover, pressed, disabled).
/// @param $state State text - 'default', 'hover', 'pressed' or 'disabled'
/// @param $foreground-colors Color list for text color
/// @param $background-colors Color list for background color
@mixin btn-color-state($state, $foreground-colors, $background-colors) {
    $state-index: 0;

    @if $state =='default' {
        $state-index: 1;
    }

    @else if $state =='hover' {
        $state-index: 2;
    }

    @else if $state =='pressed' {
        $state-index: 3;
    }

    @else if $state =='disabled' {
        $state-index: 4;
    }

    @else {
        @warn('Invalid state input ' + $state + '.');
    }

    background-color: nth($background-colors, $state-index);
    color: nth($foreground-colors, $state-index);
}

// Static button properties:
@mixin btn-common {
    outline: 0;
    position: relative;
    z-index: 80;
    cursor: pointer;
    @include flex(center, center);
    column-gap: 9px;
    transition: all 0.2s;

    &:disabled {
        cursor: default;
        pointer-events: none;
    }
}

/// Create a button by specifying all its core properties.
/// @param $foreground-colors Color map for text color
/// @param $background-colors Color map for background color
/// @param $text-size Responsive list: font size for button label
/// @param $height Responsive list: total button height
@mixin btn($foreground-colors, $background-colors, $text-size, $height) {
    @include btn-common;

    @include responsive('xl') {
        @include text(map-get($text-size, 'xl'));
        height: map-get($height, 'xl');
    }

    @include responsive('l') {
        @include text(map-get($text-size, 'l'));
        height: map-get($height, 'l');
    }

    @include responsive('m') {
        @include text(map-get($text-size, 'm'));
        height: map-get($height, 'm');
    }

    @include responsive('s') {
        @include text(map-get($text-size, 's'));
        height: map-get($height, 's');
    }

    @include responsive('xs') {
        @include text(map-get($text-size, 'xs'));
        height: map-get($height, 'xs');
    }

    @include btn-color-state('default', $foreground-colors, $background-colors);

    >.icon {
        height: 24px;
        width: 24px;
    }

    &:disabled {
        @include btn-color-state('disabled', $foreground-colors, $background-colors);

        >.icon {
            opacity: 0.4;
        }
    }

    &:hover {
        @include btn-color-state('hover', $foreground-colors, $background-colors);
        transform: scale(1.03);

        >.icon {
            transform: scale(1.1);
        }
    }

    &:active {
        @include btn-color-state('pressed', $foreground-colors, $background-colors);

        >.icon {
            transform: translateY(5px);
        }
    }
}