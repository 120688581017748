@import '_mixins_typography';
@import '_mixins_responsive';
@import '_config_typography';


p {
    @include paragraph;
}

h1 {
    @include heading-large;
    b, strong {
        font-weight: 700;
    }
}

h2 {
    @include heading-medium;
    b, strong {
        font-weight: 700;
    }
    margin-bottom: 20px;
}

h3 {
    @include heading-small;
    b, strong {
        font-weight: 700;
    }
}

a {
    &:hover {
        color: $color-primary;
    }

    &.header-link {
        color: $color-primary;
    }
    &:link {
        color: $color-secondary;
    }
    text-decoration: none;
}

body {
    @include global-default-font;
}

.text-secondary-small {
    @include global-secondary-font;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 3%;
}

.text-secondary-big {
    @include global-secondary-font;
    font-size: 140px;
    line-height: 128px;
    letter-spacing: 3%;
}