/// Put mixins related to creating certain
/// typographics
/// 
/// Note that styles that lead to actual CSS being
/// generated should not be included here, as this
/// leads to duplication in case this file is
/// included more than once.
@import '_config_colors';
@import '_config_typography';

@mixin text($font-size, $line-height: -1) {
    font-size: $font-size;
    @if $line-height > -1 {
        line-height: $line-height;
    }
}

@mixin global-default-font {
    font-family: $default-font-family;
    font-weight: $default-font-weight;
    font-style: normal;
}

@mixin global-secondary-font {
    font-family: $secondary-font-family; 
    font-weight: $default-font-weight;
    font-style: normal;
}

/// Apply common paragraph styles
/// Usually to <p> elements
@mixin paragraph {
    //Using css variable so it can be inherited from parent. Used in the dynamic color changing of the section components
    color: var(--color-theme);
    @include responsive-to('l') {
        @include text(20px, 28px);
    } @include responsive-from('l') {
        @include text(24px, 34px);
    }
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

/// Apply a large heading
/// Usually to <h1> elements
@mixin heading-large {
    color: var(--color-theme);
    @include responsive-to('s') {
        @include text(40px, 44px);
    } @include responsive-from('s') {
        @include text(64px, 72px);
    }
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
}

/// Apply a medium sized heading
/// Usually to <h2> elements
@mixin heading-medium {
    color: var(--color-theme);
    @include responsive-to('s') {
        @include text(34px, 38px);
    } @include responsive-from('s') {
        @include text(48px, 52px);
    }
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
}

/// Apply a small heading
/// Usually to <h3> elements
@mixin heading-small {
    color: var(--color-theme);
    @include responsive-to('s') {
        @include text(28px, 32px);
    } @include responsive-from('s') {
        @include text(36px, 40px);
    }
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
}

/// Apply a card title styles
@mixin card-title {
    color: var(--color-theme);
    @include text(20px, 25px);
    font-family: $secondary-font-family;
    letter-spacing: 0.05em;
    font-weight: 400;
}