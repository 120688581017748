@import '_config_layouts';

/// Put variables and mixins related to creating
/// a responsive web experience.
/// 
/// Note that styles that lead to actual CSS being
/// generated should not be included here, as this
/// leads to duplication in case this file is
/// included more than once.

/// Used in the responsive mixins to add checking for incorrect input
/// breakpoint - the input which has to be checked
/// more-allowed-vals - (optional parameter) a value which is accepted as correct other than the standart screen-sizes  
@mixin error-check($breakpoint, $more-allowed-vals: '#NULL#') {
    @if map-has-key($screen-sizes, $breakpoint) {
        @content
    }
    @else if $breakpoint == $more-allowed-vals {
        @content
    }

    @else {
        @error "No such breakpoint exists, available screen sizes are [#{map-keys($screen-sizes)}]";
    }
}

/// Apply responsive styles for a single screen range
/// Possible values for screen range are 'xs', 's', 'm', 'l' and 'xl'
@mixin responsive($single-screen-range) {
    @include error-check($single-screen-range) {
        @if ($single-screen-range =='xs') {
            @media screen and (max-width: calc(map-get($screen-sizes, 'xs') - 1px)) {
                @content
            }
        }

        @if ($single-screen-range =='s') {
            @media screen and (min-width: map-get($screen-sizes, 'xs')) and (max-width: calc(map-get($screen-sizes, 's') - 1px)) {
                @content
            }
        }

        @if ($single-screen-range =='m') {
            @media screen and (min-width: map-get($screen-sizes, 's')) and (max-width: calc(map-get($screen-sizes, 'm') - 1px)) {
                @content
            }
        }

        @if ($single-screen-range =='l') {
            @media screen and (min-width: map-get($screen-sizes, 'm')) and (max-width: calc(map-get($screen-sizes, 'l') - 1px)) {
                @content
            }
        }

        @if ($single-screen-range =='xl') {
            @media screen and (min-width: map-get($screen-sizes, 'l')) {
                @content
            }
        }
    }
}

/// Apply responsive styles from a given point onwards
/// The point can be either a specified PX value or a
/// screen label, such as 'xs', 's', 'm' etc.
@mixin responsive-from($from-point) {
    @include error-check($from-point) {
        $from-point-px: -1;

        @if type-of($from-point)==number {
            $from-point-px: $from-point;
        }

        @if type-of($from-point)==string {
            $from-point-px: map-get($screen-sizes, $from-point);
        }

        @media screen and (min-width: $from-point-px) {
            @content
        }
    }
}

/// Apply responsive styles from zero to a given point
/// The point can be either a specified PX value or a
/// screen label, such as 'xs', 's', 'm' etc.
@mixin responsive-to($to-point) {
    @include error-check($to-point) {
        $to-point-px: -1;

        @if type-of($to-point)==number {
            $to-point-px: $to-point;
        }

        @if type-of($to-point)==string {
            $to-point-px: calc(map-get($screen-sizes, $to-point) - 1px);
        }

        @media screen and (max-width: $to-point-px) {
            @content
        }
    }
}

/// Apply responsive styles from a given point to a given point
/// The points can be either specific PX values or screen
/// labels, such as 'xs', 's', 'm' etc.
@mixin responsive-range($from-point, $to-point) {
    @include error-check($from-point, 'min') {
        @include error-check($to-point, 'max') {
            $from-point-px: -1;
            $to-point-px: 0; // -1 stands for infinity, so the default here is 0
            @if type-of($from-point) == number {
                $from-point-px: $from-point;
            } @if type-of($from-point) == string {
                @if ($from-point == 'min') {
                    $from-point-px: 0px;
                } @else {
                    $from-point-px: map-get($screen-sizes, $from-point);
                }
            }
            @if type-of($to-point) == number {
                $to-point-px: $to-point;
            } @if type-of($to-point) == string {
                @if ($to-point == 'max') {
                    $to-point-px: -1;
                } @else {
                    $to-point-px: calc(map-get($screen-sizes, $to-point) - 1px);
                }
            }
            @if $to-point-px != -1 {
                @media screen and (min-width: $from-point-px) and (max-width: $to-point-px) {
                    @content
                }
            } @else { // The "to" point is infinity, so we should omit the (max-width) clause
                @media screen and (min-width: $from-point-px) {
                    @content
                }
            }
        }
    }
}