@import '_mixins_layouts';
@import '_mixins_responsive';

.equal-horizontal-layout {
  @include equal-horizontal-layout('m');
}

.horizontal-layout-2-1 {
  @include custom-horizontal-layout('m', (2, 1));
}

.horizontal-layout-1-2 {
  @include custom-horizontal-layout('m', (1, 2));
}

.horizontal-layout-3-2 {
  @include custom-horizontal-layout('m', (3, 2));
}
