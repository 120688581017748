@import '_mixins_var_to_css';

//Define here global css variables
:root {
    // Append pre-defined colors in $color-palette:
    @each $key, $value in $color-palette {
        @include define-custom-property($key, $value);
    }
}

//Depending on which custom attribute a landing-section component has [light or dark] it sets the corresponding variables
landing-section > section {
    // Append SASS variables in a CSS var format
    //Light theme - default theme
    @include define-custom-property('color-theme', $color-dark);

    //Dark theme
    &[theme~='dark'] {
        @include define-custom-property('color-theme', $color-light);
    }    
}

navigation-bar {
    @include define-custom-property('navbar-color-theme', $color-light);
    @include define-custom-property('navbar-color-text', $color-dark);

    &[theme~='dark'] {
        @include define-custom-property('navbar-color-theme', $color-light);
        @include define-custom-property('navbar-color-text', $color-dark);
    }
    &[theme~='light'] {
        @include define-custom-property('navbar-color-theme', $color-dark);
        @include define-custom-property('navbar-color-text', $color-light);
    }
}