
$default-font-family: 'Inria Sans';
$default-font-weight: 400;
$secondary-font-family: 'Lalezar';

@font-face {
    font-family: 'Inria Sans';
    src: url('../../fonts/InriaSans/InriaSans-LightItalic.woff2') format('woff2'),
        url('../../fonts/InriaSans/InriaSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inria Sans';
    src: url('../../fonts/InriaSans/InriaSans-Italic.woff2') format('woff2'),
        url('../../fonts/InriaSans/InriaSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inria Sans';
    src: url('../../fonts/InriaSans/InriaSans-BoldItalic.woff2') format('woff2'),
        url('../../fonts/InriaSans/InriaSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inria Sans';
    src: url('../../fonts/InriaSans/InriaSans-Bold.woff2') format('woff2'),
        url('../../fonts/InriaSans/InriaSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inria Sans';
    src: url('../../fonts/InriaSans/InriaSans-Light.woff2') format('woff2'),
        url('../../fonts/InriaSans/InriaSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inria Sans';
    src: url('../../fonts/InriaSans/InriaSans-Regular.woff2') format('woff2'),
        url('../../fonts/InriaSans/InriaSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lalezar';
    src: url('../../fonts/Lalezar/Lalezar-Regular.woff2') format('woff2'),
        url('../../fonts/Lalezar/Lalezar-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
