@import '_config_buttons';
@import '_mixins_buttons';

.btn {
    $foregroundColors: map-get($btn-color-primary, 'text-color-states');
    $backgroundColours: map-get($btn-color-primary, 'background-color-states');
    $textSize: map-get($btn-size-default, 'text-size');
    $height: map-get($btn-size-default, 'height');
    @include btn($foregroundColors, $backgroundColours, $textSize, $height);
    border-radius: 3px;
    padding-left: 40px;
    padding-right: 40px;
    border: none;
    min-width: 200px;
}