/// Put color definitions here
///
/// Note that styles that lead to actual CSS being
/// generated should not be included here, as this
/// leads to duplication in case this file is
/// included more than once.

/// Main color palette
$color-primary: #365257;
$color-secondary: #419bdf;
$color-accent: #8f6248;

/// Replacements for black and white
$color-dark: #312758;
$color-light: #ffffff;

/// Functional color palette
$color-success: #96cb5f;
$color-warning: #f1da83;
$color-danger: #df5a5a;
$color-highlight: #c5dc67;

//A color map used for easy generation of css variables
$color-palette: (
  'color-primary': $color-primary,
  'color-secondry': $color-secondary,
  'color-accent': $color-accent,
  'color-dark': $color-dark,
  'color-light': $color-light,
  'color-success': $color-success,
  'color-warning': $color-warning,
  'color-danger': $color-danger,
  'color-highlight': $color-highlight,
);

/// Compute a color by blending 2 colors (base and overlay)
/// The resulting color is made by putting the overlay color with its
/// specified opacity on top of the base color.
/// @param $base-color The base color upon which to perform the blending operation
/// @param $overlay-color The opaque color of the overlay
/// @param $overlay-opacity The opacity (0-100) of the overlay
@function mixed-color($base-color, $overlay-color, $overlay-opacity) {
  @return mix($base-color, $overlay-color, 100 - $overlay-opacity);
}
