@import '_config_layouts';
@import '_mixins_responsive';

/// Put layout definitions here
///
/// Note that styles that lead to actual CSS being
/// generated should not be included here, as this
/// leads to duplication in case this file is
/// included more than once.

$default-horizontal-gap: 40px;
$default-vertical-gap: 40px;

/// Include common container styles. A container is any block
/// element that grows in width to the specified max, has some
/// padding on the sides and has its content centered.
@mixin container($max-width: $width-of-content-in-perc) {
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: block;
}

/// Generate rules for flex positioning
@mixin flex($justify-content: flex-start, $align-items: flex-start) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

/// Generate a horizontal flex layout
/// with no defined ratio between children
@mixin horizontal-layout(
  $breakpoint,
  $horizontal-gap: $default-horizontal-gap,
  $vertical-gap: $default-vertical-gap
) {
  display: flex;
  flex-direction: row;
  > *:not(:last-child) {
    margin-right: $horizontal-gap;
  }
  > *:last-child {
    margin-right: 0;
  }
  @include responsive-to($breakpoint) {
    flex-direction: column;
    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: $vertical-gap;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }
}

/// Generate a horizontal flex layout
/// with equal width chilren
@mixin equal-horizontal-layout(
  $breakpoint,
  $horizontal-gap: $default-horizontal-gap,
  $vertical-gap: $default-vertical-gap
) {
  @include horizontal-layout($breakpoint, $horizontal-gap, $vertical-gap);
  @include responsive-from($breakpoint) {
    > * {
      flex: 1;
    }
  }
}

@mixin custom-horizontal-layout(
  $breakpoint,
  $ratios,
  $horizontal-gap: $default-horizontal-gap,
  $vertical-gap: $default-vertical-gap
) {
  @include horizontal-layout($breakpoint, $horizontal-gap, $vertical-gap);
  @include responsive-from($breakpoint) {
    @for $index from 1 through length($ratios) {
      $ratio: nth($ratios, $index);
      > *:nth-child(#{$index}) {
        flex: $ratio;
      }
    }
  }
}
