/* You can add global styles to this file, and also import other style files */
@import '_buttons';
@import '_typography';
@import '_colors';
@import '_layouts';

body {
    overflow: hidden;
    margin: 0;
}

figure {
    margin-block-start: 0px;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}